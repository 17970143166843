import Image1 from "../assets/VAK Learning Style - Image 1.png"
import Image2 from "../assets/Lean business tools - image 2.png"
import Image3 from "../assets/Blue Sky thinking - image 3.png"
import Stats1 from "../assets/stats1.jpg"
import Stats2 from "../assets/stats2.jpg"
import Stats3 from "../assets/stats3.jpg"
import Eagle from "../assets/eagle.jpg"
import Clients from "../assets/Our Clients.png"
import "./DestinationStyles.css";

const Destination = () => {
  return (
    <>
     <div className="impact-container">
    <div className="empower">
    <div className="row-images">
          <div className="image-tex">
            <div className="rimg">
              <img src={Stats1}/>
            </div>
            

          </div>

          <div className="image-tex">
            <div className="rimg">
              <img src={Stats2}/>
            </div>
            
          </div>

          <div className="image-tex">
            <div className="rimg">
              <img src={Stats3}/>
            </div>
           
          </div>
      </div>
      <br/>
      <h1>Who we Are</h1>


      <p>Welcome to ElevateHer, a sanctuary of empowerment where the transformative power of immersive learning meets the entrepreneurial and intrapreneurial spirit.</p>

      <div className="row-images">
          <div className="image-text">
            <div className="rimg">
              <img src={Image1}/>
            </div>
            <div className="rtext">
              <p>
              We recognise each individual’s <b>unique learning</b> style. So using our immersive learning tools and solutions, we empower intre/entrepreneurs to thrive, meeting you where and how you learn best.
              </p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image2}/>
            </div>
            <div className="rtext">
              <p>
              We use <b>global lean busines</b>s tools that are geared towards innovation, efficiency and improved communication. </p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image3}/>
            </div>
            <div className="rtext">
              <p>
              <b>BlueSky Thinking!</b> These dynamic sessions are carefully curated to empower individuals in the private and public sectors, at various levels. They are designed to develop an innovative, growth mindset in organisations.</p>
            </div>

          </div>
      </div>
      <br/>
      <h1>Our Mission</h1>

      <p>
        Our Purpose is simple: <b>“to Break barriers. And unleash potential for Entrepreneurs across Africa”</b> <br/>
        <small>“Only 16-20% of women in Sub-Saharan Africa are able to access long-term financing from formal financial institutions” – African Development Bank Report, 2022</small>
      </p> <br/>
      <h1>Our VALUES</h1> 
      <br/><br/>

     
      <div className="row-images">
        

        <div className="row-1">
      
            <p>
              <b>E</b>- Empowerment <br/><b>A</b>- Authenticity<br/><b>G</b>- Growth Mindset<br/><b>L</b>- Leadership <br/><b>E</b>- Empathy <br/>
            </p>
        
        </div>

        <div className="row-1">
          < img src={Eagle}/>

       
        </div>

      </div>
      <br/><br/>
      <h1>Our Clients</h1>

      <div className="clients">
        <img src={Clients}/>
      </div>


      
    </div>
      
      </div>
      

    </>
   
  );
};

export default Destination;
