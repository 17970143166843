import "./styles.css";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Gallery from "./routes/Gallery";
import Contact from "./routes/Contact";
import Join from "./routes/Join"
import Impact from "./routes/Impact"
import Header from "./components/Header";
import Info from "./routes/Info"
import Mission from "./routes/Mission"
import EmpowerBiz from "./routes/EmpowerBIz"
import Skybound from "./routes/Skybound"
import EmpowerHer from "./routes/Empowerher"
import Rise from "./routes/Rise"
import Kids from "./routes/Kid"
import Moti from "./routes/SkyMoti"

export default function App() {
  return (

    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/impact" element = {< Impact />} />
        <Route path="/join" element = {< Join />} />
        <Route path="/info" element = {< Info />} />
        <Route path="/mission" element = {< Mission />} />
        <Route path="/entrepreneurship" element = {< EmpowerBiz />} />
        <Route path="/intrepreneurship" element = {< Skybound />} />
        <Route path="/empowerher" element = {< EmpowerHer />} />
        <Route path="/rise" element = {< Rise />} />
        <Route path="/youngpreneur" element = {< Kids />} />
        <Route path="/moti" element = {< Moti />} />



      </Routes>
    </div>
  );
}
