import "./AboutUsStyles.css";
import {useState} from 'react';
import trip5 from "../assets/2.jpg"
import trip from "../assets/about.jpg"

const App = () => {

  return (
    <div>
      <h1>Services</h1>
      
    </div>
  );
};

export default App;
