import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Kids from "../components/Kids";
import Img from "../assets/YoungPreneur Banner.png"

function Info() {
  return (
    <>
      
      <Hero
        cName="hero-mid"
        heroImg={Img}
        title=""
        btnClass="hide"
      />
      <Kids />
      <Footer />
    </>
  );
}

export default Info;
