
import "./Impact.css";
import Image1 from "../assets/YoungPreneur- Image 1.png"
import Image2 from "../assets/YoungPreneur- Image 2.png"
import Image3 from "../assets/YoungPreneur- Image  3.png"


const Info = () => {
  return (
    <div className="impact-container">
    <div className="empower">
      <h1>YoungPreneur</h1>
      

      <p>
      Welcome to "YoungPreneur: Nurturing Tomorrow's Leaders Today" – our comprehensive entrepreneurship and work readiness programmes are tailored for students aged 6 and upwards. We are committed to equipping the next generation with essential life skills, grit, a growth mindset, and resilience to thrive in the dynamic professional landscape.
      </p>

      <div className="row-images">
          <div className="image-text">
            <div className="rimg">
              <img src={Image1}/>
            </div>
            <div className="rtext">
              <p>
              <b>Innovative Learning  Entrepreneurship Experience</b><br></br><br/>
              YoungPreneurs learn the fundamentals of entrepreneurship through interactive activities, games, and real-world scenarios.
</p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image2}/>
            </div>
            <div className="rtext">
              <p>
              <b>Creative Idea Incubation</b><br></br><br/>
              Foster creativity and innovation as YoungPreneurs incubate their own business ideas. From conceptualization to pitching, our program guides them through the exciting journey of turning imagination into entrepreneurship. </p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image3}/>
            </div>
            <div className="rtext">
              <p>
                <b>Life Skills and Grit</b><br></br><br/>
                YoungPreneurs learn practical life skills and grit necessary to navigate the challenges of the professional world. Our program focuses on critical skills such as communication, collaboration, time management, problem-solving, and perseverance, providing a solid foundation for success </p>
            </div>

          </div>
      </div>
      <br/><br></br>
      <p><a href="mailto:info@elevateher.africa"><b>Unleash </b></a>your potential, build resilience, and acquire the skills needed to thrive in the workforce of tomorrow. </p>
    </div>

    


    </div>
  );
}

export default Info;
