import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactForm from "../components/Impact";
import Img from "../assets/hub.png"

function Impact() {
  return (
    <>
      
      <Hero
        cName="hero-mid"
        heroImg={Img}
        title=""
        btnClass="hide"
      />
      <ContactForm />
      <Footer />
    </>
  );
}

export default Impact;
