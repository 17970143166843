import "./Impact.css";


const Impact = () => {
  return (
    <div className="impact-container">
    <div className="empower">
      <h1>EmpowerHub: Sparking Ideas, Igniting Insights, and Empowering Journeys!</h1>
      

      <p>Welcome to EmpowerHub – your dynamic destination for inspiration, insights, and empowerment! Explore a wealth of knowledge and resources as we curate a vibrant collection of newsletters, blogs, and news. Here at EmpowerHub, you'll learn more about the immersive tools we use to empower you. Dive into captivating lessons, entrepreneurial wisdom, and personal growth nuggets, all designed to fuel your journey towards success. Join us on this immersive experience of continuous learning, exploration, and empowerment. Welcome to the heart of inspiration – welcome to EmpowerHub!</p>
    </div>

    

    </div>

    
  );
}

export default Impact;
