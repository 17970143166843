import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import TagManager from 'react-gtm-module'

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const tagManagerArgs = {
  gtmId: 'GTM-NRMQWL3M'
}

TagManager.initialize(tagManagerArgs)

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
