import "./Impact.css";


const Impact = () => {
  return (
    <div className="impact-container">
    <div className="empower">
      <h1>SkyBound Motivational Speaking: Inspiring Entrepreneurial Excellence</h1>
      

      <p>

Elevate your event with the captivating and empowering presence of our Founder, Anthea Windvogel. As a seasoned entrepreneur, she brings a wealth of experience, resilience, and inspiration to your audience. Her motivational speaking services are tailored to ignite the entrepreneurial spirit, offering a dynamic and impactful session that resonates with both entrepreneurs and intrepreneurs.
<br/><br/><b>Why Choose SkyBound Motivational Speaking:</b>

<ul>
  <li>
    Real-World Insights: <br/>Benefit from firsthand experiences and practical insights gained from navigating the journey from Corporate boardrooms to entrepreneurial landscapes.
  </li>

  <li>
    Engaging Storytelling: <br/>Her entrepreneurial journey unfolds through engaging storytelling, creating a connection with the audience that goes beyond motivation.
  </li>

  <li>
    Tailored Content:<br/> Whether your audience consists of entrepreneurs, intrepreneurs, or a mix of both, the content is customized to address the specific needs and challenges of your audience.
  </li>
</ul>

<br></br><b>Actionable Takeaways: </b><br/> Beyond motivation, participants gain actionable takeaways and strategies to implement in their entrepreneurial endeavours.<br/>
<br/><a href="https://elevateher.africa/Join" ><b>Book  Anthea Windvogel  </b></a> for Your Next Event.
<br/>Let's create a memorable and empowering experience together!

</p>
    </div>

    

    </div>

    
  );
}

export default Impact;
