import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SkyBound from "../components/SkyMoti";
import Img from "../assets/6.png"

function Info() {
  return (
    <>
      
      <Hero
        cName="hero-mid"
        heroImg={Img}
        title=""
        btnClass="hide"
      />
      <SkyBound />
      <Footer />
    </>
  );
}

export default Info;
