
import "./Impact.css";



const Info = () => {
  return (
    <div className="impact-container">
    <div className="empower">
      <h1>EmpoweRise</h1>
      

      <p>
      Welcome to "Empower-Rise: Nurturing Tomorrow's Leaders Today" – our comprehensive work readiness program tailored for students and young graduates. We are committed to equipping the next generation with essential life skills, grit, a growth mindset, and resilience to thrive in the dynamic professional landscape.
<br></br><br/>
<h3>Empower-Rise Program Highlights:</h3>

<ul>
  <li>
    <b>Life Skills and Grit Development:</b><br/>
    Empower yourself with the practical life skills and grit necessary to navigate the challenges of the professional world. Our program focuses on critical skills such as communication, collaboration, time management, problem-solving, and perseverance, providing a solid foundation for success.
</li>

<li>
  <b>Building a Growth Mindset:</b><br/>
  Embrace the power of a growth mindset, a key driver of success in any career. Empower-Rise instils the belief that abilities and intelligence can be developed through dedication and hard work, fostering a mindset that welcomes challenges as opportunities for growth.
</li>

<li>
  <b>Professional Development Workshops:</b><br/>
  Engage in workshops designed to enhance your professional skills. From resume building and effective networking to interview techniques and workplace etiquette, FutureReady prepares you for a seamless transition from academia to the professional world.
</li>

<li>
  <b>Mentorship and Networking Opportunities:</b><br/>
  Connect with experienced mentors and fellow participants through our mentorship and networking opportunities. Gain valuable insights, guidance, and expand your professional network to kickstart your career journey.

</li>
</ul>

<br/>
<br/><a href="mailto:info@elevateher.africa" ><b>Embark</b></a> on your journey towards professional success with Empower-Rise. Unleash your potential, build resilience, and acquire the skills needed to thrive in the workforce of tomorrow. Welcome to Empower-Rise – where tomorrow's leaders are nurtured today.

</p>
    </div>

    


    </div>
  );
}

export default Info;
