import "./Impact.css";
import { Carousel } from "@mantine/carousel";
import { InstagramEmbed } from 'react-social-media-embed';
import { YouTubeEmbed } from "react-social-media-embed";

const Impact = () => {
  return (
    <div className="impact-container">
    <div className="empower">
      <h1>Impact Chronicles – Real Stories, Real Results</h1>
      

      <p>Explore the Impact Chronicles, where the narratives of empowerment come to life. Dive into testimonials and proof of work that vividly illustrate the transformative journey taken by individuals and organizations. <br/>These real stories serve as a testament to the genuine impact of our programs. <br/><br/><a href="https://elevateher.africa/Join"> <b>Join  us</b></a> in navigating through these Chronicles, where every entry is a tale of empowerment, resilience, and success within our community.</p>
    </div>

    
      <center>
        <div className="sosh">

          <div className="wert">
          <YouTubeEmbed
            url="https://youtube.com/shorts/EjkN3FM8PEs?si=fFFvhXJv-br9YXgC"
           
            width={345}
            height={467}
          />
          </div>

          <div className="wert">
          <YouTubeEmbed
            url="https://youtu.be/KAsaXD71FCk?si=80IU8Q3aCAvYqlgu"
            width={345}
            height={467}
          />
          </div>

          <div className="wert">
          <YouTubeEmbed
            url="https://youtube.com/shorts/LP0XE1kzmEc?si=b2a9xTFtayToLf98"
            width={345}
            height={467}
          />
          </div>
        
          


        </div>
      
      </center>
    
         
    
    </div>
    

    
  );
}

export default Impact;
