import { menuItemsData } from './MenuItemsData';
import MenuItems from './MenuItems';
import Logo from "../assets/logo.png";
import MobileNav from './MobileNav';
import './Navbar.css'

const Navbar = () => {
  const depthLevel = 0;
  return (
    <>
    {/* for small screens */}
    <MobileNav />

    <nav className="desktop-nav">
      <h1 className="navbar-logo">
          <img alt="imag" src={Logo} />
        </h1>
      <ul className="menus">
        {menuItemsData.map((menu, index) => {
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
    </>
  
  );
};

export default Navbar;