import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import Img from "../assets/9.jpg"
function About() {
  return (
    <>
      
      <Hero
        cName="hero-mid"
        heroImg={Img}
        title=""
        btnClass="hide"
      />
      <AboutUs />
      <Footer />
    </>
  );
}

export default About;
