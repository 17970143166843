
import "./Impact.css";
import Image1 from "../assets/INtrepreneurship - leading self.png"
import Image2 from "../assets/INtrepreneurship - leading others.png"
import Image3 from "../assets/INtrepreneurship - leading change.png"
import Anthea from "../assets/1.png"


const Info = () => {
  return (
    <div className="impact-container">
    <div className="empower">
      <h1>Intrepreneurship</h1>
      

      <p>
<br/><br/>
<h2><b>Unlocking Potential with Growth Mindset: </b></h2><br/>
Our tailored training programmes focus on instilling an eagle-like innovation mindset within intrepreneurs across all levels. We believe that fostering a growth mindset is the key to unlocking untapped potential, driving productivity, fostering innovation, and enhancing collaboration and communication.
<br/><br/><br/>
<div className="row-images">
          <div className="image-text">
            <div className="rimg">
              <img src={Image1}/>
            </div>
            <div className="rtext">
              <p>
              <b>Leading Self</b><br></br><br/>
              Our Leading Self Masterclass delves into self-awareness, resilience, and personal development strategies, setting the foundation for impactful intrapreneurial leadership. </p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image2}/>
            </div>
            <div className="rtext">
              <p>
              <b>Leading Others</b><br></br><br/>
              The Leading Others Masterclass focuses on effective communication, team dynamics, and collaboration, enabling you to elevate your leadership skills and foster a positive and productive work environment. </p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image3}/>
            </div>
            <div className="rtext">
              <p>
                <b>Leading Change</b><br></br><br/>
              The Leading Change Masterclass provides you with tools and insights into change management, innovation strategies, and the ability to adapt and thrive in evolving work landscapes. </p>
            </div>

          </div>
      </div>
      </p>
      <p>
<h2><b>Targeted Across Levels: </b></h2><br/>
Our training program recognizes the diversity of talent and experience across different organizational levels, ensuring relevance and impact at every stage of your professional journey.
<br></br><br/>
<a href="mailto:info@elevateher.africa"><b>Join us</b></a> and soar to new heights as an empowered and innovative intrepreneur.

</p>
    </div>

    


    </div>
  );
}

export default Info;
