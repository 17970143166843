
import "./Impact.css";
import Image1 from "../assets/EmpowerH1.png"
import Image2 from "../assets/EmpowerH2.png"
import Image3 from "../assets/EmpowerH3.png"

const Info = () => {
  return (
    <div className="impact-container">
    <div className="empower">
      <h1>#EmpowerHer</h1>
      

      <p>
      Welcome to #EmpowerHer: Breaking Barriers, Building Futures" – a transformative suite of services tailored to empower women in the workplace and unleash the entrepreneurial spirit within.
      <br/>
      <div className="row-images">
          <div className="image-text">
            <div className="rimg">
              <img src={Image1}/>
            </div>
            <div className="rtext">
              <p>
              <b>AllyHer</b><br></br><br/>
              A transformative suite of services dedicated to empowering women in the workplace and fostering allyship that drives inclusion and diversity. 
</p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image2}/>
            </div>
            <div className="rtext">
              <p>
              <b>BrandHer</b><br></br><br/>
              Cultivate a powerful personal brand and advance your career. <br/>Empower yourself with the tools and strategies to navigate career challenges.
  </p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image3}/>
            </div>
            <div className="rtext">
              <p>
                <b>Speaker</b><br></br><br/>
               Elevate your event with the captivating and empowering presence of our Founder, Anthea Windvogel. With more than 20 years of building brands for multinational companies, she brings a wealth of experience, resilience, and inspiration to your audience.</p>
            </div>

          </div>
      </div>
      <br/><br></br>
<a href="https://elevateher.africa/Join"> <b>Book an EmpowerHer </b></a> session and embark on a journey of empowerment, growth, and success. Break barriers, build futures – because empowered women empower the world.
<br/><br/>
<small>“Each time a woman stands up for herself, without knowing it possibly, without claiming it, she stands up for all women.” Maya Angelou</small>
</p>


    </div>

    


    </div>
  );
}

export default Info;
