
import "./Impact.css";
import imgg from "../assets/eagle.jpg"


const Info = () => (
  <div className="impact-container">
    <div className="empower">
      <h1>Mission and Values</h1>


      <p><h3>Mission:</h3>
        At ElevateHer, our mission is to empower individuals to discover their untapped potential and thrive in both personal and business endeavours. Through immersive learning tools and solutions, we guide intrepreneurs and entrepreneurs to unlock their unique strengths, fostering growth and innovation from grassroots to executive levels.
        <br></br> <br/></p>
        <h3>Our Values:</h3>
        < div className="cent">

        <center><b>E-A-G-L-E</b></center>
        <br/>
        <center>
        <img src={imgg} />
      </center>
        </div>
      
      
      
      <p>
        <ul>
          <li><b>Empowerment: </b>
        We believe in empowering individuals to soar to new heights, unlocking their full potential and embracing a sense of self-discovery and personal growth.
        </li>
        <br/>
        <li> <b>Authenticity: </b>
        We value authenticity as the cornerstone of our approach. Like the eagle, we encourage individuals to embrace their true selves, fostering genuine exploration of identity and purpose.
        </li>
       <br/>
       <li> <b>Growth Mindset: </b> 
        Adopting a Growth Mindset is crucial for continuous improvement. We promote a culture of continuous learning, resilience, and adaptability, embodying the spirit of the eagle's ever-expanding wingspan.
       </li>
        <br/>
        <li><b>Leadership: </b> 
        Empathetic leadership is ingrained in our DNA. We inspire individuals to lead with compassion, understanding, and a keen awareness of the needs and feelings of others.
        </li>
        <br/>
        <li><b>Excellence:</b>
        Striving for excellence is at the core of our values. Like the eagle, we aim for precision and distinction, encouraging individuals to reach their highest potential in both personal and professional spheres.
        </li>
       <br/><br/>
        </ul>
        These <b>EAGLE</b> values serve as the foundation for ElevateHer, guiding us as we help individuals develop an eagle mindset and leadership skills, ultimately enabling them to thrive in every aspect of their lives.
      </p>
    </div>




  </div>
)

export default Info;
