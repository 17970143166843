import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Bizz from "../components/EmpowerBiz";
import Img from "../assets/bizz.png"

function Info() {
  return (
    <>
     
      <Hero
        cName="hero-mid"
        heroImg={Img}
        title=""
        btnClass="hide"
      />
      <Bizz />
      <Footer />
    </>
  );
}

export default Info;
