import Hero from "../components/Hero";
import Footer from "../components/Footer";
import EmpowerHer from "../components/Empowerher";
import Img from "../assets/empoher.png"

function Info() {
  return (
    <>
      
      <Hero
        cName="hero-mid"
        heroImg={Img}
        title=""
        btnClass="hide"
      />
      <EmpowerHer />
      <Footer />
    </>
  );
}

export default Info;
