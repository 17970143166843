
import "./Impact.css";


const Info = () => {
  return (
    <div className="impact-container">
    <div className="empower">
      <h1>Company Information</h1>
      

      <p>Welcome to ElevateHer, a sanctuary of empowerment where the transformative power of immersive learning meets the entrepreneurial and intrapreneurial spirit. <br/> <br/> I'm Anthea Windvogel, the Founder & Chief Excitement Officer of ElevateHer.
My 2 kids, 13 and 8 were both labelled by their teachers and the school system as “ slow learners, dyslexic, special”.  Despite investing significantly in extra tuition, psychologists, and occupational therapy, my children continued to lag behind their peers and grapple with the limitations imposed upon them.
Fuelled by years of frustration and financial investment, I made a pivotal decision to change course. I delved into a personal journey with a life coach, leveraging the potent Personal Model Canvas tool. This transformative experience not only unveiled my own identity, purpose, and life role but also brought to light the unique learning styles of my children.
Armed with this newfound understanding, I successfully unlocked the untapped potential within both of my kids. <br/><br/> It is from this deeply personal odyssey that ElevateHer emerges, committed to empowering individuals, both in their personal and business lives, through learning, self-discovery, and an immersive approach to business education.
<br></br><br></br>
With a Head Quarters based In South Africa and in Dublin, Ireland, we use lean business models and tools to empower individuals and businesses to rise above limitations, innovate and make meaningful impact in the societies that they operate in, 
<br></br><b><a href="mailto:info@elevateher.africa">Join us</a></b> on this transformative journey. Together, let's elevate the spirit of learning and unlock the extraordinary within. Welcome to a place where self-discovery meets entrepreneurial success – welcome to ElevateHer.
</p>
    </div>

    


    </div>
  );
}

export default Info;
