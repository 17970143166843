export const menuItemsData = [
  {
    title: "Home",
    url: "/",
    cName: " menu-items",
    icon: "fa-solid fa-house-user"
  },
  {
    title: "Services",
    url: "",
    cName: "menu-items",
    submenu: [
      {
        title: 'Entrepreneurs',
        url: '/entrepreneurship',
      },
      {
        title: 'Intrepreneurs',
        url: '/intrepreneurship',
      },
      {
        title: 'YoungPreneurs ',
        url: '/youngpreneur',
      },
      {
        title: '#EmpowerHer ',
        url: '/empowerher',
      },
    ],
  },

  { 
    title: "Impact Chronicles",
    url: "/contact",
    cName: "menu-items",
    icon: "fa-solid fa-hand-fist"
  },


  {
    title: "Contact Us",
    url: "/Join",
    cName: "menu-items",
    icon: "fa-sharp fa-solid fa-circle-notch"
  },

  
];
