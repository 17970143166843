import Destination from "../components/Destination";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Main from "../assets/hero (1).png"
import Navbar from "../components/Navbar";
import About from "../routes/About";

function Home() {
  return (
    <>
     
      <Hero
        cName="hero"
        heroImg= {Main}
        buttonText="Read more"
        url = "#destination"
        btnClass="hide"
      />
      <Destination />
      <Footer />
    </>
  );
}

export default Home;
