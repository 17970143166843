import "./AboutUsStyles.css";
const App = () => {
  
  return (
    <div>
      <h1>About Us</h1>
      
    </div>
  );
};

export default App;
