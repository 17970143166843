import "./FooterStyles.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div>
          <div className="footer-logo1">
            <img alt="footer" src="log2.png"></img>
          </div> 
        </div>
        <div>
          <a href="https://youtube.com/@ElevateHer-ib8zm?si=BEJrXhJr2SaSKQqS">
            <i className="fa-brands fa-youtube-square"></i>
          </a>
          <a href="https://instagram.com/elevateher_consulting_services?igshid=MzNlNGNkZWQ4Mg==">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
         
          <a href="https://www.linkedin.com/in/antheawindvogel?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
            <i className="fa-brands fa-linkedin"></i>
          </a>
          
        </div>
      </div>

      <div className="bottom">
        <div>
          <h4>Get in Touch</h4>
          <a href="https://wa.me/27713650456">Call: +27 71 365 0456 </a>
          <a href="mailto:info@elevateher.africa">
            Email: info@elevateher.africa
          </a>
          <a href="#">HQ: Kylami, JHB SA</a>
        </div>

        <div>
          <h4>Empower</h4>
          <a href="https://elevateher.africa/contact"> Impact Chronicles</a>
          <a href="https://elevateher.africa/empowerher"> EmpowerHer</a>
          <a href="https://elevateher.africa/Join"> Join a Circle</a>

        </div> 

        <div>
          <h4>Help</h4>
          <a href="mailto:info@elevateher.africa">Support</a>
          <a href="mailto:info@elevateher.africa">Troubleshooting</a>
          <a href="https://wa.me/27713650456">Talk to Us</a>
        </div>

        <div>
          <h4>Others</h4>
          <a href="#">Terms of Service</a>
          <a href="#">Privacy Policy</a>
          <a href="#">License</a>
        </div>
      </div>
      <br></br>
      <hr></hr>
      <p>
        <br></br> Developed by{" "}
        <a className="vun" href="http://vundlapty.co.za">
          Vundla Group{" "}
        </a>
      </p>
    </div>
  );
};

export default Footer;
