import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Img from "../assets/services.jpg"
function Gallery() {
  return (
    <>
      
      <Hero
        cName="hero-mid"
        heroImg={Img}
        title=""
        btnClass="hide"
      />
      <Services />
      <Footer />
    </>
  );
}

export default Gallery;
