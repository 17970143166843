import "./Join.css";
import { useRef } from 'react';
import emailjs from "emailjs-com"

const Join=() =>{
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lx7aihi', 'template_u1sb8eg', form.current, 'glrwESOHAhK9HCLh-')
    .then((result) => {
      console.log(result.text);
  }, (error) => {
      console.log(error.text);
  });
    
    e.target.reset()
  };
  return (
    <div className="about-container">
      <center>
      <h1>CONNECT WITH US</h1>
      <h2></h2> <br/>

<p>
Looking to elevate you and your business? <br/>
Contact us below to discuss a customised immersive learning package that meets your needs<br/> <br/>

HQ: <br/>
Kyalami, Bluehills, South Africa <br/>
Dublin, Ireland <br/>
Whatsapp: 071 365 0456 <br/>
Email:<b> <a href="mailto:info@elevateher.africa">info@elevateher.africa</a></b> <br/>

</p>
      </center>

     
     <div className="footer">
      <div className="top">
        <div>
          <a href="https://youtube.com/@ElevateHer-ib8zm?si=BEJrXhJr2SaSKQqS">
            <i className="fa-brands fa-youtube-square"></i>
          </a>
          <a href="https://instagram.com/elevateher_consulting_services?igshid=MzNlNGNkZWQ4Mg==">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
         
          <a href="https://www.linkedin.com/in/antheawindvogel?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
            <i className="fa-brands fa-linkedin"></i>
          </a>
          
        </div>
      </div>

      <div>

       <center>
       <div className="form-container">
        <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="name" placeholder=" Name" required />
        <input type="email" name="email" placeholder=" Email" required/>
        <input type="text" name="bname" placeholder=" Business Name" required/>
        <select name="cars" id="cars">
            <option disabled selected value="">Select an Option</option>
            <option value="customised_learning_package">Customised Learning Package</option>
            <option value="join_a_circle">Join a circle</option>
            <option value="empowerher_Speaker">EmpowerHer Speaker</option>
        </select>
        <input type="tel" name="number" placeholder="Phone Number" required />
        <button type="submit"> Submit</button>
      </form>
      </div>
       </center>
        
      </div>
      <br></br>
      <hr></hr>
      <p>
      For a customised empowerment package, send an email with your inquiry to <b>info@elevateher.africa</b>
      </p>
    </div>
      
      
        
    </div>
  );
}

export default Join;
