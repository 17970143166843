
import "./DestinationStyles.css";
import Image1 from "../assets/Entrepreneurship - Image 1.png"
import Image2 from "../assets/enterprise development.png"
import Image3 from "../assets/online circles.png"
import imgg from "../assets/eagle.jpg"


const Info = () => {
  return (
    <>
      <div className="impact-container">
    <div className="empower">
      <h1>Entrepreneurship</h1>


      <p>Welcome to ElevateHer Entrepreneurship Services, where we understand the unique challenges that entrepreneurs face – the constraints of time and resources. At ElevateHer, we've tailored our services to meet the specific needs of time-poor and money-poor entrepreneurs, providing lean business tools designed to catalyse ideation, innovation, growth, and scalability.</p>

      <div className="row-images">
          <div className="image-text">
            <div className="rimg">
              <img src={Image1}/>
            </div>
            <div className="rtext">
              <p>
              <b>One on One Mentoring & Support</b><br></br><br/>
              Elevate your entrepreneurial journey with personalised, one-on-one support. Our "Over the Shoulder Support" program offers you an “accountability partner” for guidance, insights, and feedback to address your specific challenges and propel your business forward.</p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image2}/>
            </div>
            <div className="rtext">
              <p>
              <b>Enterprise Development</b><br></br><br/>
              Our comprehensive Enterprise Development Programme spans 12 to 16 weeks, providing a structured and immersive approach to business growth. Through a combination of offline and online resources, mentorship, and practical exercises, entrepreneurs gain the knowledge and skills needed to take their ventures to new heights. </p>
            </div>

          </div>

          <div className="image-text">
            <div className="rimg">
              <img src={Image3}/>
            </div>
            <div className="rtext">
              <p>
              <b>Online Circles</b><br></br><br/>
              Join our dynamic online community through "Elevate Circles." These circles serve as a platform for support, collaboration, and networking. Engage with like-minded individuals, share experiences, and build valuable connections that can propel your entrepreneurial journey forward. </p>
            </div>

          </div>
      </div>
      <br/>

      <p><a><b>Join us</b></a> as we redefine entrepreneurship, making it accessible and impactful for those with a passion to innovate and succeed. Time-poor and money-poor no more – ElevateHer is here to elevate your entrepreneurial journey</p>
      
      </div>
      </div>
    </>
  );
}

export default Info;
